import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';
import urlParser from '../../utils/urlParser';
import withLocation from '../../utils/withLocation';

const MenuLinksContainerOuter = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const MenuLinksContainer = styled(motion.div)`
  height: fit-content;
  width: 100%;
`;

const MenuItem = styled(motion.a)`
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-size: 36px;

  line-height: 36px;
  // letter-spacing: 0.03em;
  color: #ffffff;
  cursor: pointer;
  clear: both;
  max-width: 370px;
  font-style: normal;
  text-transform: uppercase;
  line-height: 1.3;

  &.active {
    color: rgb(255, 34, 238);
  }
  @media (max-width: 900px) {
    font-size: 26px;
    line-height: 26px;
    margin-bottom: 30px;
  }
`;

const MenuAccordion = styled.div`
  color: #fff;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;

  @media (max-width: 900px) {
    padding-left: 0;
    padding-right: 0;
  }

  ul {
    width: 100%;
  }

  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox']:checked + label + .sub-menu {
    max-height: 500px;
    padding-top: 15px;
    padding-bottom: 15px;

    @media (max-width: 900px) {
      max-height: fit-content;
    }
  }

  input[type='checkbox']:checked + label:before {
    transform: rotate(180deg);
  }

  .top-level {
    font-family: Montserrat, sans-serif;
    font-weight: 800;
    font-size: 32px;
    // letter-spacing: 0.03em;
    border-bottom: 1px solid #4d4d4d;
    font-style: normal;

    > a {
      cursor: pointer;
      padding: 20px 0;
      display: block;
      width: 100%;
    }

    label {
      cursor: pointer;
      padding: 20px 0;
      display: block;
      width: 100%;

      &:before {
        transform: rotate(0deg);
        transition: all ease 500ms;
        content: '';
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIEdlbmVyYXRvcjogU1ZHIFJlcG8gTWl4ZXIgVG9vbHMgLS0+DQo8c3ZnIHdpZHRoPSI4MDBweCIgaGVpZ2h0PSI4MDBweCIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgY2xhc3M9Imljb24iICB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTkwMy4yMzIgMjU2bDU2Ljc2OCA1MC40MzJMNTEyIDc2OCA2NCAzMDYuNDMyIDEyMC43NjggMjU2IDUxMiA2NTkuMDcyeiIgZmlsbD0iI2ZmZmZmZiIgLz48L3N2Zz4=');
        background-size: 30px;
        width: 30px;
        height: 30px;
        display: inline-block;
        float: right;
        position: relative;
        top: 2px;
      }
    }

    .sub-menu {
      display: flex;
      flex-wrap: wrap;
      max-height: 0;
      padding-top: 0;
      padding-bottom: 0;
      transition: ease all 500ms;
      overflow: hidden;
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      max-width: 980px;

      > li {
        width: 33.33%;
      }

      &__summary {
        text-transform: none !important;
        width: 100%;
        margin-bottom: 30px;
        letter-spacing: 0 !important;
        font-size: 16px;
        margin-top: -10px;
        color: #fff !important;

        span {
          max-width: 600px;
          display: block;
          line-height: 1.4;
        }
      }

      @media (max-width: 900px) {
        flex-wrap: wrap;
      }
    }

    .sub-menu > li {
      // text-transform: uppercase;
      color: #c6c6c6;
      @media (max-width: 900px) {
        width: 100%;
        // margin-bottom: 30px;

        ul {
          margin-bottom: 30px;
        }
      }
    }

    .sub-sub-menu {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .sub-sub-menu > li {
      text-transform: none !important;
      margin-bottom: 20px;
      letter-spacing: 0;
      color: #fff;
    }
  }
`;

const MenuItemContainer = styled(motion.div)`
  margin-bottom: 35px;
`;

const container = {
  hidden: {
    opacity: 0,
    scale: 1,
    transition: {
      delay: 0.0,
      when: 'afterChildren',
      staggerChildren: 0.03,
    },
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0.0,
      when: 'beforeChildren',
      staggerChildren: 0.05,
    },
  },
};

const item = {
  hidden: {
    y: 20,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
  },
  transition: {
    duration: 0.4,
  },
};

const itemReverse = {
  hidden: {
    y: 40,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
  },
  transition: {
    duration: 0.4,
  },
};

export class MainMenuLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
      menuUnloading: false,
    };
  }

  componentDidMount = () => {
    if (!this.state.menuUnloading) {
      this.setState({ visible: true });
    }
  };

  componentWillReceiveProps = nextProps => {
    if (!this.state.menuUnloading) {
      if (nextProps.menuUnloading) {
        this.setState({
          menuUnloading: nextProps.menuUnloading,
          visible: false,
        });
      }
    }
  };

  render() {
    return (
      <MenuLinksContainerOuter>
        <MenuLinksContainer
          variants={container}
          initial="hidden"
          animate={this.state.visible ? 'visible' : 'hidden'}
        >
          <MenuAccordion>
            <ul>
              {this.props.data.body.map(slice => {
                if (slice.slice_type === '1st_level') {
                  return (
                    <li
                      className="top-level"
                      key={`menu-item-${slice.primary.link_index}`}
                    >
                      {slice.primary.show_menu === 'yes' ? (
                        <div>
                          <input
                            type="checkbox"
                            name={`menu-item-${slice.primary.link_index}`}
                            id={`menu-item-${slice.primary.link_index}`}
                          />
                          <label
                            htmlFor={`menu-item-${slice.primary.link_index}`}
                          >
                            {slice.primary.link_text}
                          </label>
                          <ul className="sub-menu">
                            {slice.primary.summary && (
                              <li className="sub-menu__summary">
                                <span>{slice.primary.summary}</span>
                              </li>
                            )}
                            {this.props.data.body.map(subSlice => {
                              if (
                                subSlice.slice_type === '2nd_level' &&
                                subSlice.primary.link_index ===
                                  slice.primary.link_index
                              ) {
                                return (
                                  <li key={subSlice.primary.link_text}>
                                    {subSlice.primary.nav_link.url &&
                                    subSlice.primary.nav_link.url !==
                                      'https://#' ? (
                                      <a href={subSlice.primary.nav_link.url}>
                                        {subSlice.primary.link_text}
                                      </a>
                                    ) : (
                                      <span>{subSlice.primary.link_text}</span>
                                    )}
                                    <ul className="sub-sub-menu">
                                      {subSlice.items.map(subSubSlice => (
                                        <li
                                          key={subSubSlice.third_level_link.url}
                                        >
                                          <a
                                            href={
                                              subSubSlice.third_level_link.url
                                            }
                                          >
                                            {subSubSlice.third_level_link_text}
                                          </a>
                                        </li>
                                      ))}
                                    </ul>
                                  </li>
                                );
                              }
                              return null;
                            })}
                          </ul>
                        </div>
                      ) : (
                        <a href={slice.primary.nav_link.url}>
                          {slice.primary.link_text}
                        </a>
                      )}
                    </li>
                  );
                }
                return null;
              })}
            </ul>
          </MenuAccordion>
        </MenuLinksContainer>
      </MenuLinksContainerOuter>
    );
  }
}

export default withLocation(MainMenuLinks);
