export default function urlParser(link) {
  if (link.url) {
    return link.url;
  } else if (link.slug) {
    let slugString = '/' + link.slug + '/';
    return slugString;
  } else {
    return null;
  }
}
