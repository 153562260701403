import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

import Facebook from '../../images/assets/socialIcons/facebook.svg';
import Twitter from '../../images/assets/socialIcons/twitter.svg';
import LinkedIn from '../../images/assets/socialIcons/linkedin.svg';
import Instagram from '../../images/assets/socialIcons/instagram.svg';

const ContactContainer = styled.div`
  margin-top: -20px;
  @media (max-width: 900px) {
    margin-top: 0px;
  }
`;

const Title = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 59px;
  color: #a0a0a0;
  text-transform: uppercase;
`;

const ContactTextItem = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 27px;
  color: #ffffff;
`;

const SocialIconContainer = styled.div`
  display: flex;
  margin-top: 10px;
`;

const SocialIconItemOne = styled.div`
  margin-right: 10px;
`;

const SocialIconItem = styled.div`
  margin-left: 10px;
  margin-right: 10px;
`;

const ContactDetailsContainerOuter = styled(motion.div)``;

const ContactDetailsContainerInner = styled.div``;

const container = {
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      delay: 0.1,
      when: 'afterChildren',
      staggerChildren: 0.05,
    },
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.75,
    },
  },
};

export class ContactDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
      menuUnloading: false,
    };
  }

  componentDidMount = () => {
    if (!this.state.menuUnloading) {
      this.setState({ visible: true });
    }
  };

  componentWillReceiveProps = nextProps => {
    if (!this.state.menuUnloading) {
      if (nextProps.menuUnloading) {
        this.setState({
          menuUnloading: nextProps.menuUnloading,
          visible: false,
        });
      }
    }
  };

  render() {
    return (
      <ContactContainer>
        <ContactDetailsContainerOuter
          variants={container}
          initial="hidden"
          animate={this.state.visible ? 'visible' : 'hidden'}
        >
          <Title>{this.props.data.contact_title_text.text}</Title>
          <ContactDetailsContainerInner>
            <ContactTextItem>
              <a href={'mailto:' + this.props.data.contact_email_address.text}>
                {this.props.data.contact_email_address.text}
              </a>
            </ContactTextItem>
            <ContactTextItem>
              <a href={'tel:' + this.props.data.contact_telephone_number.text}>
                {this.props.data.contact_telephone_number.text}
              </a>
            </ContactTextItem>
            <SocialIconContainer>
              <SocialIconItemOne>
                {/*
                <a href={this.props.data.facebook_link.url}>
                  <Facebook height="16px" width="16px" />
                  </a>*/}
              </SocialIconItemOne>
              <SocialIconItem>
                {/*
                <a href={this.props.data.linkedin_link.url}>
                  <LinkedIn height="16px" width="16px" />
                </a>
                */}
              </SocialIconItem>
              <SocialIconItem>
                {/*
                <a href={this.props.data.twitter_link.url}>
                  <Twitter height="16px" width="16px" />
                </a>
                */}
              </SocialIconItem>
            </SocialIconContainer>
          </ContactDetailsContainerInner>
        </ContactDetailsContainerOuter>
      </ContactContainer>
    );
  }
}

export default ContactDetails;
