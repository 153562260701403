import React from 'react';
import Arrow from './assets/arrow.svg';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import linkStripper from '../../utils/link-stripper';
import { Link } from 'gatsby';
import { buttonReset } from '../../utils/styles';

const ButtonNotUnderlinedContainer = styled(motion.div)`
  display: flex;
  border-width: 0px;
  border-style: solid;
  border-color: ${props => props.color};
  width: fit-content;
  margin: ${props => props.margin};
  cursor: pointer;
  align-items: center;
  font-family: Montserrat;
`;

const ButtonText = styled.div`
  font-weight: bold;
  font-size: ${props => props.fontSize};
  @media (max-width: 735px) {
    font-size: ${props => props.fontSizeMobile};
  }
  color: #fff;
`;

const ArrowContainer = styled(motion.div)`
  margin-left: 10px;
  padding-top: 1px;
  display: flex;
  align-items: center;
`;

const ButtonNotUnderlinedContainerInner = styled.div`
  display: flex;
`;

const Button = styled.button`
  ${buttonReset};

  @media (max-width: 735px) {
    text-align: left;
  }
`;

function InnerComponent({ link, target, children }) {
  if (link) {
    if (link.includes('.pdf')) {
      return (
        <a href={link} target={target}>
          {children}
        </a>
      );
    } else {
      return (
        <a href={linkStripper(link)} target={target}>
          {children}
        </a>
      );
    }
  }

  return <Button className="cursor-normal">{children}</Button>;
}

const ButtonNotUnderlined = props => {
  const [active, setActive] = React.useState(false);
  return (
    <ButtonNotUnderlinedContainer
      color={props.color}
      margin={props.margin}
      whileHover={{ translateX: '5px' }}
      onHoverStart={() => setActive(!active)}
      onHoverEnd={() => setActive(!active)}
      onClick={() => {
        props.onClickData && props.onClickData();
      }}
    >
      <InnerComponent link={props.link} target={props.target}>
        <ButtonNotUnderlinedContainerInner>
          <ButtonText
            color={
              props.color && props.color !== null ? props.color : '#FFFFFF'
            }
            fontSize={props.fontSize ? props.fontSize : '20px'}
            fontSizeMobile={
              props.fontSizeMobile ? props.fontSizeMobile : '18px'
            }
          >
            {props.buttonText}
          </ButtonText>
          <ArrowContainer
            color="#FFF"
            animate={active ? { translateX: '10px' } : { translateX: '0px' }}
            transition={{ duration: 0.5 }}
          >
            <Arrow
              style={{
                fill: '#FFF',
              }}
            />
          </ArrowContainer>
        </ButtonNotUnderlinedContainerInner>
      </InnerComponent>
    </ButtonNotUnderlinedContainer>
  );
};

export default ButtonNotUnderlined;
