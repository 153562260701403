import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import convertHtml from '../../utils/convertHtml';

const LocationLinksContainer = styled.div``;

const LocationItem = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-size: 50px;
  margin-bottom: 35px;
  line-height: 60px;
  letter-spacing: 0.03em;
  color: #ffffff;
  cursor: pointer;
  transition: 150ms;
  clear: both;
  max-width: 370px;
`;

const Title = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 59px;
  color: #a0a0a0;
  text-transform: uppercase;
`;

const Content = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.03em;
  color: #ffffff;
  white-space: pre-wrap;
`;

const LocationDetailContainer = styled(motion.div)``;

const container = {
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      delay: 0.0,
      when: 'afterChildren',
      staggerChildren: 0.05,
    },
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.6,
    },
  },
};

export class LocationLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
      menuUnloading: false,
    };
  }

  componentDidMount = () => {
    if (!this.state.menuUnloading) {
      this.setState({ visible: true });
    }
  };

  componentWillReceiveProps = nextProps => {
    if (!this.state.menuUnloading) {
      if (nextProps.menuUnloading) {
        this.setState({
          menuUnloading: nextProps.menuUnloading,
          visible: false,
        });
      }
    }
  };

  render() {
    return (
      <LocationLinksContainer>
        <LocationDetailContainer
          variants={container}
          initial="hidden"
          animate={this.state.visible ? 'visible' : 'hidden'}
        >
          <Title>{this.props.data.location_title_text.text}</Title>
          <Content
            dangerouslySetInnerHTML={convertHtml(
              this.props.data.location_text.text
            )}
          />
        </LocationDetailContainer>
      </LocationLinksContainer>
    );
  }
}

export default LocationLinks;
